import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from "react-router"



import axios from "axios";
import { BASE_URL } from "../Components/constant";
import Header from '../Components/header';

import { ToastContext } from "../Common/ToastProvider";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

const Create = () => {
    const classes = useStyles();
    let history = useHistory()



    const { showToast } = React.useContext(ToastContext);

    const [user, setUser] = useState({
        name: "",
        email: "",
        mobile_number: "",
        password: "",
        role: ""
    });
    const handleChange = (event: any) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };




    React.useEffect(() => {
        document.title = "Smitch | Create User "
        getToken();
    }, []);

    const getToken = () => {
        return localStorage.getItem("access_token");
    };

    const Headers = {
        'Content-Type': 'application/json',
        "encryption": false,
        "access_token": getToken()
    }
    const handleCreate = async (e: any) => {
        e.preventDefault()
        try {
            const result = await axios.post(BASE_URL + 'signup', user, { headers: Headers })
            if (result.data != null) {
                history.push("/ss-home")
                showToast("User created successfully !", "success");
            }
        } catch (error) {
            console.log(error);
            showToast("Something went wrong!", "error");

        }

    }
    return (
        <React.Fragment>
            <Header />

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>


                    <form className={classes.form} noValidate>
                        <div style={{ textAlign: "center", paddingTop: "5rem", marginBottom: "0px" }}>
                            <h3 >
                                User Creation
                </h3>
                            <p>Create a user and assign a role !</p>
                        </div>
                        <Grid container spacing={2}>

                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="name"
                                    name="name"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    autoFocus
                                    value={user.name}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={user.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="mobile_number"
                                    label="Mobile Number"
                                    name="mobile_number"
                                    autoComplete="mobile"
                                    value={user.mobile_number}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={user.password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel value="worst" control={
                                    <Radio
                                        onChange={handleChange}
                                        value={"CUSTOMER_SUPPORT"}
                                        name="role"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />} label="Customer Support" />

                                <FormControlLabel value="best" control={<Radio
                                    onChange={handleChange}
                                    value={"DEVELOPER"}
                                    name="role"
                                    inputProps={{ 'aria-label': 'B' }}
                                />} label="Developer" />
                            </Grid>
                        </Grid>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleCreate}

                        >
                            Create User
                     </Button>

                    </form>
                </div>
            </Container>
        </React.Fragment>
    );
}


export default Create
