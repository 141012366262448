import React, { useState } from "react";
import { Button, Typography, CardHeader, CardContent, Card, Grid, makeStyles, Container, CssBaseline, TextField } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';



import Copyright from "../Components/copyright";
import "../Style/Main.css";
import { httpClient, baseUrlChange } from "../Common/Service";
import { ToastContext } from "../Common/ToastProvider";
import moment from "moment";
import Header from '../Components/header';
import { BASE_URL } from '../Components/constant';
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';






const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        width: "25ch",
        flexGrow: 1,
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(4),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(2),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    tab: {
        paddingTop: 30,
        width: "100%",
    },
    deletebutton:{
        margin: theme.spacing(1),

    }
}));

const SsUser = () => {
    const classes = useStyles();
    const { showToast } = React.useContext(ToastContext);
    let history = useHistory();


    const [state, setState] = useState({
        email: "",
        devType: "",
        mobile: "",
        user_id: "",
        device_id: ""
    });

    const [users, setUsers] = useState<any[]>([]);
    const [devices, setDevices] = useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const [opendevice, setOpendevice] = React.useState(false);

    const [scroll, setScroll] = React.useState('paper');
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState("SS")
    const [loading, setLoading] = useState(false)
    const [deviceData, setDeviceData] = React.useState([]);
    const [openAlert, setOpenAlert] = React.useState(false);




    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        console.log("tab", newValue)

        if (newValue === 0) {
            history.push("/ss-home");
            setData("SS")
        }
        if (newValue === 1) {
            history.push("/sh-home");
            setData("SH")
        }
        if (newValue === 2) {
            history.push("/sw-home");
            setData("SW")
        }
    }

    const handleClickOpen = (scrollType: any) => () => {
        setOpen(true);
        setScroll(scrollType);
    };


    const handleClickOpenDevice = (scrollType: any) => () => {
        setOpendevice(true);
        setScroll(scrollType);
    };
    const handleCloseDevice = () => {
        setOpendevice(false);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
        //@ts-ignore
        baseUrlChange(event.target.value)
    };


    const userHandle = async () => {
      

        try {
            //@ts-ignore

            if (state.email) {
                setLoading(true)
                const result = await httpClient("getUserFullProfile", "POST", {
                    email: state.email, "project": data
                });
                if (!result.data && result.data === undefined) return showToast(result.message, "error");
                setUsers([result.data]);
                setDevices(result.data.devices)
                setLoading(false)
            }

            else if (state.user_id) {
                setLoading(true)
                const result = await httpClient("getUserFullProfile", "POST", {
                    user_id: state.user_id, "project": data
                });

                if (!result.data && result.data === undefined) return showToast(result.message, "error");
                setUsers([result.data]);
                setDevices(result.data.devices);
                setLoading(false)

            }
            else if (state.mobile) {
                setLoading(true)
                const result = await httpClient("getUserFullProfile", "POST", {
                    mobile: state.mobile, "project": data
                });

                if (!result.data && result.data === undefined) return showToast(result.message, "error");
                setUsers([result.data]);
                setDevices(result.data.devices);
                setLoading(false)

            }
            else {
                if (!state.device_id) {
                    
                    return showToast("Enter a device id", "warn");
                }
                setLoading(true)
                setUsers([])
                setDevices([])
                const result = await httpClient("getDeviceInformation", "POST", {
                    "device_id": state.device_id,
                    "project": data
                })
                if (!result.data && result.data === undefined) return showToast(result.message, "error");
                //@ts-ignore
                setDeviceData([result.data.data])
                setLoading(false)
            }
        } catch (error) {
            console.error(error);


        }
    };
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                //@ts-ignore
                descriptionElement.focus();
            }
        }
    }, [open]);
    React.useEffect(() => {
        getToken();

    }, []);

    const handleClickOpenAlert = () => {
        setOpenAlert(true);

    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const getToken = () => {
        return localStorage.getItem("access_token");
    };

    const Headers = {
        'Content-Type': 'application/json',
        "encryption": false,
        "access_token": getToken()
    }

    const resetLinkHandle = async (val: any) => {
        const { email } = val
        try {
            const result = await axios.post(BASE_URL + "sendResetPasswordLink", { "email": email, "link_type": "password_reset_token", "project": data }, { headers: Headers });
            if (result != null) {
                showToast("Reset email sent successfully! ", "success");
            }
        } catch (error) {
            console.error(error);
            //@ts-ignore
            showToast(error.response.data.errorMessage, "error")
        }
    }



    const handleUnblock = async (deviceId: any) => {
        try {
            const result = await axios.post(BASE_URL + "unblockStolenDevice",
                {
                    "device_id": deviceId,
                    "project": data
                }, { headers: Headers });
            if (result != null) {
                showToast("Device unblocked  successfully! ", "success");
            }
        } catch (error) {
            console.error(error);
            //@ts-ignore
            showToast(error.response.data.errorMessage, "error")
        }
    }



    const deleteDeviceHandle = async (deviceId: any) => {
        try {
            const result = await axios.post(BASE_URL + "deleteDevice",
                {
                    "device_id": deviceId,
                    "project": "SS"
                }, { headers: Headers });
            if (result != null) {
                setOpenAlert(false)
                //@ts-ignore
                showToast(result.data.message, "success");
            }
        } catch (error) {
            console.error(error);
            //@ts-ignore
            showToast(error.response.data.errorMessage, "error")
        }
    }



    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <main>
                <div className={classes.heroContent}>
                    <Container>
                        <div>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                //@ts-ignore
                                scroll={scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                            >
                                <DialogTitle id="scroll-dialog-title">RAW Details of Smitch Secure Customer  </DialogTitle>
                                <DialogContent dividers={scroll === 'paper'}>
                                    <DialogContentText
                                        id="scroll-dialog-description"
                                        ref={descriptionElementRef}
                                        tabIndex={-1}
                                    >
                                        {JSON.stringify(users, null, 2)}
                                        {/* {JSON.stringify(users)} */}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Ok
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                        <Grid>
                            <Paper className={classes.tab}>
                                <Tabs
                                    value={value}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Smitch Secure" />
                                    <Tab label="Smitch Home" />
                                    <Tab label="Smitch Wear" />
                                </Tabs>
                            </Paper>
                        </Grid>
                        <Grid container className="maincontainer">
                            <Grid item md={2}>
                                <form className={classes.root} noValidate autoComplete="off">
                                    <TextField
                                        id="outlined-basic"
                                        label="Email id"
                                        variant="outlined"
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            OR
                            <Grid item md={2}>
                                <form className={classes.root} noValidate autoComplete="off">
                                    <TextField
                                        id="outlined-basic"
                                        label="Mobile No"
                                        name="mobile"
                                        variant="outlined"
                                        type="tel"
                                        value={state.mobile}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            OR
                            <Grid item md={2}>
                                <form className={classes.root} noValidate autoComplete="off">
                                    <TextField
                                        id="outlined-basic"
                                        label="User Id"
                                        name="user_id"
                                        variant="outlined"
                                        type="text"
                                        value={state.user_id}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            OR
                            <Grid item md={2}>
                                <form className={classes.root} noValidate autoComplete="off">
                                    <TextField
                                        id="outlined-basic"
                                        label="Device Id"
                                        name="device_id"
                                        variant="outlined"
                                        type="text"
                                        value={state.device_id}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            <Grid />
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={userHandle}
                            >
                                FIND
                            </Button>
                        </Grid>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                <Grid className="maincontainer">
                        {users.length === 0 && deviceData.length === 0 && (
                            <h2>Your seach result will display here!</h2>
                        )}
                    </Grid>
                    {loading ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /> </div> :
                        <Grid container spacing={4} className="maincontainer">
                            {users.map((val, i) => (
                                <Grid item key={i} xs={12} sm={6} md={6}>
                                    <Card className={classes.card} >
                                        <h4 style={{ textAlign: "center" }}>Send reset password link <IconButton aria-label="send" color="primary" onClick={() => resetLinkHandle(val)}>
                                            <SendIcon />
                                        </IconButton></h4>
                                        <Button ></Button>
                                    </Card>
                                    <br></br>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <Typography
                                                className={classes.title}
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                Customer's Details
                                        </Typography>
                                            <CardHeader
                                                action={
                                                    <IconButton aria-label="settings" onClick={handleClickOpen('paper')}>
                                                        <LaunchIcon />
                                                    </IconButton>
                                                }
                                                title={val.name}
                                            />
                                            <Typography>Email : {val.email}</Typography>
                                            <Typography>City : {val.city}</Typography>
                                            <Typography>Mobile No : {val.mobile}</Typography>
                                            <Typography>Mobile Type : {val.mobile_type}</Typography>
                                            <Typography>Account Created With : {val.login_type}</Typography>
                                            <Typography>App Version : {val.app_version}</Typography>
                                            <Typography>Home Name : {val.home_names.join()}</Typography>
                                            <Typography>Registered On : {moment(val.createdAt).format('LLLL')}</Typography>
                                            <Typography>Last Login : {moment(val.last_login_timestamp).format('LLLL')}</Typography>
                                        </CardContent>
                                        <div>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}>
                                                        Device Types Lite Details
                                            </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                    <span >
                                                        {/* 
                                                        // @ts-ignore */}
                                                        {val.device_types_lite.map((item, index) => (
                                                            <pre style={{
                                                                wordBreak:"break-word",
                                                                whiteSpace:"pre-wrap",
                                                                display:"flex"
                                                                }}>
                                                                <code key={index}>
                                                                    {/* 
                                                              // @ts-ignore */}
                                                                    {index+1}:   {JSON.stringify(item)}
                                                                    <Divider/>
                                                                </code>
                                                            </pre>
                                                        ))}
                                                    </span>
                                                    </AccordionDetails>
                                                </Accordion>
                                                </div>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Container>
            { devices.length!=0 && <Container className={classes.cardGrid} maxWidth="md">
                    <Grid container spacing={2}>
                        {devices.map((val, i) => (
                            <Grid container key={i} xs={12} sm={6} md={6}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography
                                            className={classes.title}
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            Device {i + 1}'s Details
                                    </Typography>

                                        <Typography gutterBottom variant="h6" component="h2">
                                            Name : {val.device.name}
                                        </Typography>
                                        <Typography>Home Name : {val.device.home_name}</Typography>
                                        <Typography>Access Type : {val.access_type}</Typography>
                                        <Typography>
                                            Device Type :{val.device.device_type}
                                        </Typography>
                                        <Typography> Device Id :{val.device.device_id}</Typography>
                                        <Typography>
                                            Camera Model : {val.device.camera_document.camera_model}
                                        </Typography>
                                        <div>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.heading}>
                                                        Camera Details
                                            </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography variant="body2">
                                                        Night Vision Auto On :{" "}
                                                        {val.device.camera_document.night_vision.isAuto
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Night Vision Manual On :{" "}
                                                        {val.device.camera_document.night_vision.isManual
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Object Tracking :{" "}
                                                        {val.device.camera_document.object_tracking
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Human Detection :{" "}
                                                        {val.device.camera_document.human_detection
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Camera Switch on :{" "}
                                                        {val.device.camera_document.camera_switch_on_off
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Camera Status Indicator :
                                                    {val.device.camera_document.camera_status_indicator
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        SD Card Save :{" "}
                                                        {val.device.camera_document.sd_card_save
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Camera Mic On :{" "}
                                                        {val.device.camera_document.camera_mic
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Motion Detection :{" "}
                                                        {val.device.camera_document.motion_detection
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Motion Detection Type :{" "}
                                                        {val.device.camera_document.motion_detection_type}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Sound Detection :{" "}
                                                        {val.device.camera_document.sound_detection
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Sound Detection Type :{" "}
                                                        {val.device.camera_document.sound_detection_type}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Camera Timezone :{" "}
                                                        {val.device.camera_document.camera_timezone}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <Typography>
                                            Subscription Type : {val.device.subscription.type}
                                        </Typography>
                                        <Typography>
                                            {" "}
                                        Firmware Version : {val.device.firmware_version}
                                        </Typography>
                                        <Typography>
                                            {" "}
                                        Firmware Update :
                                        {val.device.firmware_update ? "Yes" : "No"}
                                        </Typography>
                                        <Typography>
                                            Activated : {val.device.activated ? "Yes" : "No"}
                                        </Typography>
                                        <Typography>
                                            Device Added:   {moment(val.device_add_time).format('LLLL')}
                                        </Typography>
                                        
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
               
                </Container>
}
                <Container className={classes.cardGrid} maxWidth="md">
                <Grid container spacing={2} >
                {deviceData.map((device, i) => (
                    <Grid item key={i} xs={12} sm={6} md={6} style={{ margin: "auto", justifyContent: "center", marginTop: "-5rem" }}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <CardHeader
                                    action={
                                        <IconButton aria-label="settings" onClick={handleClickOpenDevice('paper')}>
                                            <LaunchIcon />
                                        </IconButton>
                                    }
                                    //@ts-ignore
                                    title={device.name}
                                />
                                <Typography>
                                    {/* 
                                                                // @ts-ignore */}
                                    Name : {device.name}</Typography>
                                <div>
                            <Dialog
                                open={opendevice}
                                onClose={handleCloseDevice}
                                //@ts-ignore
                                scroll={scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                            >
                                <DialogTitle id="scroll-dialog-title">RAW Details of Smitch Secure Device  </DialogTitle>
                                <DialogContent dividers={scroll === 'paper'}>
                                    <DialogContentText
                                        id="scroll-dialog-description"
                                        ref={descriptionElementRef}
                                        tabIndex={-1}
                                    >
                                        {JSON.stringify(device, null, 2)}
                                        {/* {JSON.stringify(users)} */}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDevice} color="primary">
                                        Ok
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                Device Id : {device.device_id}</Typography>
                                                                {/* 
                                                                // @ts-ignore */}
                                                                <Typography>Manufacturer : {device.manufacturer}</Typography>
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                    Version : {device.version}
                                                                </Typography>
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                    Device Type : {device.device_type}
                                                                </Typography>
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                    Activated : {device.activated ? "YES" : "NO"}
                                                                </Typography>
                        
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                    Firmware update : {device.firmware_update ? "YES" : "NO"}
                                                                </Typography>
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                    Firmware Version : {device.firmware_version}
                                                                </Typography>
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                    Home Name : {device.home_name}
                                                                </Typography>
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                    Admin UserId : {device.admin_user_id}
                                                                </Typography>
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                    Reported Stolen : {device.reported_stolen ? "Yes" : "No"}
                                                                </Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                {device.reported_stolen ? <Button variant="outlined" color="primary" onClick={() => handleUnblock(device.device_id)}>
                                                UNBLOCK DEVICE
                                                </Button> : null}
                                                                <Typography>
                                                                    {/* 
                                                                // @ts-ignore */}
                                                                    Device Added: {moment(device.createdAt).format('LLLL')}
                                                                </Typography>
                                                                <Button
                                    size="medium"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.deletebutton}
                                    startIcon={<DeleteIcon />}
                                    //@ts-ignore
                                    onClick={() => (handleClickOpenAlert())}
                                >
                                    Delete
                                </Button>
                                <div>

                                    <Dialog
                                        open={openAlert}
                                        onClose={handleCloseAlert}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Make sure you want to remove this device?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseAlert} color="primary">
                                                No
                                            </Button>
                                            <Button 
                                            //@ts-ignore
                                            onClick={() => deleteDeviceHandle(device.device_id)}
                                            color="primary"
                                            autoFocus>
                                                Yes
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.heading}>
                                                        Camera Details
                                            </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography variant="body2">
                                                        Camera Model :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.camera_model}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Night Vision Auto On : {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.night_vision.isAuto
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Night Vision Manual On :{/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.night_vision.isManual
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Object Tracking :{/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.object_tracking
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Human Detection : {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.human_detection
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Camera Switch on :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.camera_switch_on_off
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Camera Status Indicator :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.camera_status_indicator
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        SD Card Save :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.sd_card_save
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Camera Mic On :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.camera_mic
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Motion Detection :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.motion_detection
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Motion Detection Type :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.motion_detection_type}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Motion Detection Area :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.motion_detection_area}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Sound Detection :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.sound_detection
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Sound Detection Type :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.sound_detection_type}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Camera Timezone :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.camera_timezone}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Stream Resolution :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.stream_resolution}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Baby Cry Detection :
                                                                            {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.baby_cry_detection ? "Yes" : "No"}
                                                    </Typography>
                                                    <p>
                                                        HLS LINK :
                                                    
                                                        {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.hls_link}
                                                    </p>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Divider/>
                                                        {/* 
                                        // @ts-ignore */}
                                            {device.camera_document.cloud_details!=null?
                                            <div>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.heading}>
                                                        Cloud Details
                                            </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography variant="body2">
                                                    Order No : {/* 
                                                        // @ts-ignore */}
                                                        {device.camera_document.cloud_details.order_no}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                    Total Price : {/* 
                                                        // @ts-ignore */}
                                                        {device.camera_document.cloud_details.total_price}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Is Enabled :  {/* 
                                                            // @ts-ignore */}
                                                        {device.camera_document.cloud_details.enabled?"Yes":"No"}
                                                    </Typography>
                                                    <Typography variant="body2">        
                                                    Plan Id :  {/* 
                                                            // @ts-ignore */}
                                                        {device.camera_document.cloud_details.plan_id}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                    DataLife : {/* 
                                                            // @ts-ignore */}
                                                        {device.camera_document.cloud_details.dataLife}
                                                    </Typography>
                                                
                                                    <Typography variant="body2">
                                                    Payment Voucher : 
                                                                    {/* 
                                                                // @ts-ignore */}
                                                        {device.camera_document.cloud_details.payment_voucher}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>:null}
                                        <Divider/>
                                                        {/* 
                                        // @ts-ignore */}
                                            {device.camera_document.goscam_api_device_information!=null?
                                            <div>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.heading}>
                                                        Goscam Device info
                                            </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography variant="body2">
                                                        CMDType :  {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.CMDType}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Hz : {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.Hz}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_SSID :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_SSID}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_free_size :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_free_size}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_hardware_version :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_hardware_version}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_id :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_id}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_name :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_name}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_software_version :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_software_version}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_total_size :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_total_size}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_type :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_type}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_used_size :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_used_size}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        a_wifi_mac :
                                                {/* 
                                        // @ts-ignore */}
                                                        {device.camera_document.goscam_api_device_information.a_wifi_mac}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>:null
                                                }
                                        <Divider/>
                                    <div>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.heading}>
                                                        Subscription Details
                                            </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography variant="body2">
                                                        Subscription Type :{/* 
                                                        // @ts-ignore */}
                                                        {device.subscription.type}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Subscription Active :
                                                {/* 
                                                        // @ts-ignore */}
                                                        {device.subscription.active
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Free trial used : {/* 
                                                            // @ts-ignore */}
                                                        {device.subscription.free_trial_used
                                                            ? "Yes"
                                                            : "No"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Subscription Id :
                                                                    {/* 
                                                    // @ts-ignore */}
                                                        {device.subscription.subscription_id}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Subscription Created :
                                                                    {/* 
                                                                // @ts-ignore */}
                                                        {moment(device.subscription.added_to_subscription_on).format('LLLL')}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Subscription Removed :
                                                                    {/* 
                                                                // @ts-ignore */}
                                                        {moment(device.subscription.removed_from_subscription_on).format('LLLL')}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <Divider/>   
                                         {/* 
                                                                // @ts-ignore */}              
                                        {device.notification!=null ?
                                        <div>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.heading}>
                                                    Notification Details
                                            </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography variant="body2">
                                                    Notification Id  :{/* 
                                                        // @ts-ignore */}
                                                        {device.notification.notification_id}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                    
                                                    Alert type :
                                                    {/* 
                                                        // @ts-ignore */}
                                                        {device.notification.alert_type}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                    created On : {/* 
                                                            // @ts-ignore */}
                                                        {moment(device.notification.createdAt).format('LLLL')}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>:null}
                                        <Divider/>   
                                               {/* 
                                                                // @ts-ignore */}              
                                        {device.cameraVideoFile!=null ?
                                        <div>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.heading}>
                                                    Camera Video File Details
                                            </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography variant="body2">
                                                    Video Id  :{/* 
                                                        // @ts-ignore */}
                                                        {device.cameraVideoFile.video_id}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                    
                                                    Alert type :
                                                    {/* 
                                                        // @ts-ignore */}
                                                        {device.cameraVideoFile.alert_type}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                    created On : {/* 
                                                            // @ts-ignore */}
                                                        {moment(device.cameraVideoFile.createdAt).format('LLLL')}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>:null}
                                            </CardContent>
                                            </Card>
                                        </Grid>
                                        ))
                                    }
                                        </Grid>
                                        </Container>
                        </main>
            {/* Footer section */}
            <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    IoT Monks Private Limited
             </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    component="p"
                >
                    support@mysmitch.com | sales@mysmitch.com{" "}
                </Typography>
                <Copyright />
            </footer>
            {/* End footer */}
        </React.Fragment>
    );
};

export default SsUser;
