import React, { useState } from 'react';
import { AppBar, Typography, Drawer, Grid, makeStyles } from '@material-ui/core';

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems, secondaryListItems, devListItems, AnalyticsListItems } from '../Pages/MenuList';

import { baseUrlChange } from "../Common/Service";
import { useHistory, } from "react-router-dom";
import jwt_decode from "jwt-decode";

const drawerWidth = 240;



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        color: 'white',
        width: 185

    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));
export default function Header() {
    const classes = useStyles();
    let history = useHistory();
    const [state, setState] = useState({
        email: "",
        devType: "",
        mobile: "",
    });

    const [token, setToken] = useState("")


    const handleLogout = () => {
        localStorage.clear();
        history.push("/");
    };
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    //base url change
    const handleChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
        //@ts-ignore
        baseUrlChange(event.target.value)
        //@ts-ignore


    };




    React.useEffect(() => {
        getToken()

    }, []);


    const getToken = () => {
        let data = localStorage.getItem("developer_token")
        if (data === "undefined") {
            return null
        }
        else {
            //@ts-ignore
            let decoded: any = jwt_decode(data)
            setToken(decoded.permissions)

        }

    }

    const getDevToken = () => {
        let data = localStorage.getItem("developer_token")
        if (data === "undefined") {
            return false
        }
        return true
    }



    return (

        <div>
            <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img
                        src={require("../../src/SMITCH.png")}
                        alt="mail logo"
                        width="40"
                        height="40"
                    />
                    <Typography className={classes.title} variant="h6" noWrap>
                        &nbsp; SMITCH{" "}
                    </Typography>

                    {
                        <Grid>
                            {/* <Grid item>
                                <FormControl variant="outlined" className={classes.root}>
                                    <InputLabel id="select-outlined-label" style={{ color: "white" }} >
                                        User Source
                                </InputLabel>
                                    <Select
                                        labelId="select-outlined-label"
                                        id="simple-select-outlined"
                                        name="devType"
                                        label="User Type"
                                        value={state.devType}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="SS">Smitch Secure</MenuItem>
                                        <MenuItem value="SS">Smitch Home</MenuItem>
                                        <MenuItem value="SW">Smitch Wearables</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid> */}
                        </Grid>
                    }
                    <IconButton onClick={handleLogout} color="inherit" >
                        <ExitToAppIcon />
                    </IconButton>

                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                {token.includes("ANALYTICS") ? null : <List>{mainListItems}</List>}
                {token.includes("SH") ? <List>{devListItems}</List> : null}
                <Divider />

                {token.includes("ADMINPANEL") ? <List> {secondaryListItems}</List> : null}
                {token.includes("ANALYTICS") ? <List> {AnalyticsListItems}</List> : null}

            </Drawer>

        </div>
    );
}

