import React, { useState } from "react";
import { Button, Typography, Grid, makeStyles, Paper, Box, Link, TextField } from '@material-ui/core';
import { useHistory } from "react-router"
import { ToastContext } from "../Common/ToastProvider";
import Copyright from '../Components/copyright'
import axios from "axios";
import { BASE_URL } from "../Components/constant";




const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/1600x900/?architecture)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



const SignUp = () => {
    const { showToast } = React.useContext(ToastContext);
    let history = useHistory()
    const classes = useStyles();


    React.useEffect(() => {
        document.title = "Smitch Admin Panel  | Sign Up"
    }, []);
    const [user, setUser] = useState({
        name: "",
        email: "",
        mobile_number: "",
        password: "",
        role: ""
    });


    const handleChange = (event: any) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };



    const handleSignup = async (e: any) => {
        e.preventDefault()
        const result = await axios.post(BASE_URL + 'signup', user, { headers: { "encryption": false } })
        if (result.status == 201) {
            showToast("Successfully created a wish wfor ", "success")
            history.push("/")
        }
    }



    return (
        <div>
            <Grid container component="main" className={classes.root}>
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>

                        <img
                            src={require("../../src/SMITCH.png")}
                            alt="mail logo"
                            width="60"
                            height="60"
                        />
                        <br></br>
                        <Typography component="h1" variant="h5">
                            Welcome ! Sign up here!
                         </Typography>
                        <br></br>
                        <div>
                            <form className={classes.form} noValidate>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    autoFocus
                                    id="Name"
                                    label="Name "
                                    name="name"
                                    autoComplete="Name"
                                    value={user.name}
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="Email"
                                    label="Email "
                                    name="email"
                                    autoComplete="Email"
                                    autoFocus
                                    value={user.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="Mobile"
                                    label="Mobile "
                                    name="mobile_number"
                                    autoComplete="Mobile"

                                    value={user.mobile_number}
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={user.password}
                                    onChange={handleChange}
                                />

                                <Button

                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSignup}

                                >
                                    Signup
                                </Button>
                                <Grid container>

                                    <Grid item>
                                        <Link href="/" variant="body2">
                                            {"Already sign up? Sign In here"}
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Box mt={8}>
                                    <Copyright />
                                </Box>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SignUp


