import React from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import Link from '@material-ui/core/Link';
import IconButton from "@material-ui/core/IconButton";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import ReplayIcon from '@material-ui/icons/Replay';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SearchIcon from '@material-ui/icons/Search';
import TextsmsIcon from '@material-ui/icons/Textsms';



export const mainListItems = (
    <div>
        <Link href="/ss-home">
            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Find " />
            </ListItem>
        </Link>
        <Link href="/shhome">

            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <BarChartIcon />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem>
        </Link>
        <Link href="/pass">
            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <ReplayIcon
                        />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Change Password" />
            </ListItem>
        </Link>
    </div>
);


export const secondaryListItems = (
    <div>
        <Link href="/create">
            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <PersonAddIcon />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Create User" />
            </ListItem>
        </Link>
    </div>
);


export const devListItems = (
    <div>
        <Link href="/stats">

            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <TrendingUpIcon />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Live Stats" />
            </ListItem>
        </Link>


        <Link href="/docs">
            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <DescriptionIcon
                        />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Swagger Docs" />
            </ListItem>
        </Link>

        <Link href="/wish">
            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <TextsmsIcon
                        />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Write Wish Text" />
            </ListItem>
        </Link>


        
    </div>
);

export const AnalyticsListItems = (
    <div>
        <Link href="/stats">

            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <TrendingUpIcon />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Customer" />
            </ListItem>
        </Link>
        <Link href="/pass">
            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <ReplayIcon
                        />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Change password" />
            </ListItem>
        </Link>
        <Link href="/shhome">
            <ListItem button>
                <ListItemIcon>
                    <IconButton>
                        <BarChartIcon />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem>
        </Link>
    </div>
);