import React from "react";
import { Table, Accordion, CssBaseline, Container, AccordionDetails, AccordionSummary, TableBody, TableContainer, TableHead, TableRow, Paper, Checkbox, Typography, Theme, createStyles, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Header from '../Components/header';

import axios from "axios";
import { BASE_URL } from "../Components/constant";
import '../Style/InternalUsers.css'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: "100%",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },

        root: {
            width: "100%",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: "25%",
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
            flexBasis: "25%",
        },
        secondaryHeadingtwo: {
            fontSize: theme.typography.pxToRem(15),
            margin: theme.spacing(0, 2),
            color: theme.palette.text.secondary,
        },
    })
);

const Peoples = () => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const [users, setUsers] = React.useState([]);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    React.useEffect(() => {
        handleSignup();
        getToken();
    }, []);

    const getToken = () => {
        return localStorage.getItem("access_token");
    };

    const handleSignup = async () => {
        const result = await axios.get(BASE_URL + "listAllUserPermissions", {
            headers: {
                encryption: false,
                access_token: getToken(),
            },
        });
        setUsers(result.data.data);
    };



    return (

        <React.Fragment>

            <Header />

            <Container maxWidth="md" className="main">
                <CssBaseline />

                <TableContainer component={Paper} >
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead></TableHead>

                        <TableBody>
                            {users.map(({ email, id, name, permissions, mobile_number }, i) => (
                                <TableRow key={i}>
                                    <div className={classes.root}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    {i + 1}
                                                </Typography>

                                                <Typography className={classes.heading}>
                                                    {name}
                                                </Typography>
                                                <Typography className={classes.secondaryHeading}>
                                                    {email}
                                                </Typography>
                                                <Typography className={classes.secondaryHeadingtwo}>
                                                    {mobile_number}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    {/* @ts-ignore */}
                                                    {Object.keys(permissions).map((item, i) => (
                                                        <div className="project" key={i}>
                                                            <Typography variant="h6" gutterBottom >Project : {item}</Typography>
                                                            {Object.keys(permissions[item]).map((data, i) => (
                                                                <div className="col" key={i}>
                                                                    <Typography variant="subtitle2" >{data}</Typography>
                                                                    {Object.keys(permissions[item][data]).map(
                                                                        (list) => (
                                                                            //@ts-ignore
                                                                            <div className="list"><Typography variant="subtitle2" ><Checkbox checked={permissions[item][data][list].is_active} /> {permissions[item][data][list].permissionType}</Typography>
                                                                                {/* <Checkbox
                                                                                    checked={checked}
                                                                                    onChange={handleChange}
                                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                /> */}
                                                                            </div>

                                                                        )
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>


            </Container>
        </React.Fragment>
    );
};

export default Peoples;
