
const BASE_URL =
    process.env.REACT_APP_BASE_URL || "https://api.admin-panel.mysmitch.com/v1/adminPanel/"
export { BASE_URL }

const SWAGGER_SECURE = process.env.REACT_APP_SWAGGER_SECURE || "https://api.ss.mysmitch.com/v1/adminPanel/docs"
export { SWAGGER_SECURE }

const SWAGGER_HOME = process.env.REACT_APP_SWAGGER_SECURE || "https://api.sh.mysmitch.com/v1/adminPanel/docs"
export { SWAGGER_HOME }

const SWAGGER_WEARABLES = process.env.REACT_APP_SWAGGER_SECURE || "https://api.sw.mysmitch.com/v1/adminPanel/docs"
export { SWAGGER_WEARABLES }

const SWAGGER_SC_BASE = process.env.REACT_APP_SWAGGER_SHF || "https://api.sc.mysmitch.com/v1/adminPanel/"

export { SWAGGER_SC_BASE }


const SWAGGER_SSO = process.env.REACT_APP_SWAGGER_SSO || "https://api.sso.mysmitch.com/v1/adminPanel/docs"

export { SWAGGER_SSO }


const SC_INTRIGATION = process.env.REACT_APP_INTRIGATION || "https://api.int.sc.mysmitch.com/v1/adminPanel/docs"

export { SC_INTRIGATION }

const SMITCH_STORE = process.env.REACT_APP_SMITCH_STORE || "https://api.store.mysmitch.com/v1/adminPanel/docs"

export { SMITCH_STORE }

const NEW_BASE_URL =
    process.env.REACT_APP_NEW_BASE_URL || "https://api.admin-panel.mysmitch.com/v1/"
export { NEW_BASE_URL }


