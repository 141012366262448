import React from "react";
import "../Style/PageNotFound.css";
const PagenotFound = () => {
    return (
        <div>

            <div id="main">
                <div className="fof">
                    <h1>Page not found</h1>
                    <p>Error 404</p>
                    <p><a href="/">Goto Home</a></p>

                </div>
            </div>
        </div>
    );
};

export default PagenotFound;
