import React from "react";
import SignIn from './Pages/Signin';
import SsUser from './Pages/SsUsers'
import PagenotFound from './Pages/PageNotfound'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "./Common/ToastProvider";
import AuthenticatedRoute from './Components/auth';
import Dashboard from './Pages/Dashboard';
import ShUser from './Pages/ShUsers'
import Peoples from './Pages/InternalUsers';
import SignUp from './Pages/Signup';
import swaggerDocs from './Swagger/Swagger';
import Create from './Pages/CreateUser';
import ResetPassword from './Pages/ResetPassword';
import Customer from './Pages/Customer';
import Stats from './Pages/LiveStats';
import SsCustomer from './Pages/DateWiseSsUsers';
import SwCustomer from './Pages/SwCustomer';
import SwUser from './Pages/SwUsers'
import WishTextHandler from './Pages/WishTextHandler'

const App: React.FC<any> = () => {

  return (
    <div className="App">
      <ToastProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={SignIn} />
            <AuthenticatedRoute
              path="/ss-home"
              exact
              component={SsUser}
            />
            <AuthenticatedRoute
              path="/dashboard"
              exact
              component={Dashboard}
            />
            <AuthenticatedRoute
              path="/sh-home"
              exact
              component={ShUser}
            />
            <AuthenticatedRoute
              path="/sw-home"
              exact
              component={SwUser}
            />
            <AuthenticatedRoute
              path="/docs"
              component={swaggerDocs}
            />
            <AuthenticatedRoute
              path="/permissions"
              component={Peoples}
            />
            <AuthenticatedRoute
              path="/create"
              component={Create}
            />
            <AuthenticatedRoute path="/wish" component={WishTextHandler} />
            <Route path="/signup" component={SignUp} />
            <AuthenticatedRoute path="/pass" component={ResetPassword} />
            <AuthenticatedRoute path="/stats" component={Stats} />
            <AuthenticatedRoute path="/shhome" component={Customer} />
            <AuthenticatedRoute path="/sshome" component={SsCustomer} />
            <AuthenticatedRoute path="/swhome" component={SwCustomer} />
            <Route component={PagenotFound} />
          </Switch>
        </Router>
      </ToastProvider>
    </div >
  )

}

export default App;

