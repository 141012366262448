import React, { useState } from "react";
import { Button, Typography, CardHeader, Paper, Tabs, Tab, CardContent, Card, Grid, makeStyles, Container, CssBaseline, TextField } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Copyright from "../Components/copyright";
import "../Style/Main.css";
import { httpClient } from "../Common/Service";
import { ToastContext } from "../Common/ToastProvider";
import moment from "moment";
import Header from '../Components/header';
import { BASE_URL } from '../Components/constant';
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';





const useStyles = makeStyles((theme) => ({

    root: {
        margin: theme.spacing(1),
        width: "25ch",
        flexGrow: 1,
    },
    tabroot: {
        width: "100%",
        paddingTop: 30,


    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(4),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),

    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(2),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    tab: {
        paddingTop: 70
    },
    button: {
        margin: theme.spacing(1),
    }
}));

const ShUser = () => {
    const classes = useStyles();
    const { showToast } = React.useContext(ToastContext);
    let history = useHistory();

    const [state, setState] = useState({
        email: "",
        devType: "",
        user_id: "",
        mobile: "",
        device_id: ""
    });

    const [users, setUsers] = useState<any[]>([]);
    const [devices, setDevices] = useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [value, setValue] = React.useState(1);
    const [data, setData] = React.useState("SH")
    const [loading, setLoading] = useState(false)
    const [openevent, setOpenevent] = React.useState(false);
    const [deviceData, setDeviceData] = React.useState([]);
    const [opendevice, setOpendevice] = React.useState(false);




    const handleClickOpen = (scrollType: any) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
        //@ts-ignore
    };

    const handleClickOpenDevice = (scrollType: any) => () => {
        setOpendevice(true);
        setScroll(scrollType);
    };
    const handleCloseDevice = () => {
        setOpendevice(false);
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        console.log("tab", newValue)
        if (newValue === 0) {
            history.push("/ss-home");
            let data = 'SS'
            setData("SS")
            // baseUrlChange(data)
        }
        if (newValue === 1) {
            history.push("/sh-home");
            let data = 'SH'
            setData("SH")
            // baseUrlChange(data)
        }
        if (newValue === 2) {
            history.push("/sw-home");
            let data = 'SW'
            setData("SW")
            // baseUrlChange(data)
        }
    }

    const handleClickOpenEvent = (scrollType: any) => () => {
        setOpenevent(true);
        setScroll(scrollType);
    };

    const handleCloseEvent = () => {
        setOpenevent(false);
    };

    const userHandle = async () => {

        try {
            //@ts-ignore

            if (state.email) {
                setLoading(true)
                const result = await httpClient("getUserFullProfile", "POST", {
                    email: state.email, "project": data
                });

                if (!result.data && result.data === undefined) return showToast(result.message, "error");
                setUsers([result.data]);
                setDevices(result.data.devices);
                setLoading(false)
            }
            else if (state.user_id) {
                setLoading(true)
                const result = await httpClient("getUserFullProfile", "POST", {
                    user_id: state.user_id, "project": data
                });

                if (!result.data && result.data === undefined) return showToast(result.message, "error");
                setUsers([result.data]);
                setDevices(result.data.devices);
                setLoading(false)

            }
            else if (state.mobile) {
                setLoading(true)
                const result = await httpClient("getUserFullProfile", "POST", {
                    mobile: state.mobile, "project": data
                });
                if (!result.data && result.data === undefined) return showToast(result.message, "error");
                setUsers([result.data]);
                setDevices(result.data.devices);
                setLoading(false)
            }
            else {
                if (!state.device_id) {
                    return showToast("Enter a device id", "warn");
                }
                setLoading(true)
                setUsers([]);
                setDevices([]);
                const result = await httpClient("getDeviceInformation", "POST", {
                    "device_id": state.device_id,
                    "project": data
                })
                if (!result.data && result.data === undefined) return showToast(result.message, "error");
                if (result.data) {
                    //@ts-ignore
                    setDeviceData([result.data.data])
                    setLoading(false)
                }

            }
        } catch (error) {
            console.error(error);
            setLoading(false)
        }
    };
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                //@ts-ignore
                descriptionElement.focus();
            }
        }
    }, [open]);
    const descriptionElementReftwo = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                //@ts-ignore
                descriptionElement.focus();
            }
        }
    }, [open]);


    React.useEffect(() => {
        getToken();

    }, []);

    const getToken = () => {
        return localStorage.getItem("access_token");
    };

    const Headers = {
        'Content-Type': 'application/json',
        "encryption": false,
        "access_token": getToken()
    }

    const resetLinkHandle = async (val: any) => {
        const { email } = val
        try {
            const result = await axios.post(BASE_URL + "sendResetPasswordLink", { "email": email, "link_type": "password_reset_token", "project": data }, { headers: Headers });
            if (result != null) {
                showToast("Reset email sent successfully! ", "success");
            }
        } catch (error) {
            console.error(error);
            //@ts-ignore
            showToast(error.response.data.errorMessage, "error")

        }
    }

    const handleUnblock = async (deviceId: any) => {

        try {
            const result = await axios.post(BASE_URL + "unblockStolenDevice",
                {
                    "device_id": deviceId,
                    "project": data
                }, { headers: Headers });

            if (result != null) {
                showToast("Device unblocked  successfully! ", "success");
            }
        } catch (error) {
            console.error(error);
            //@ts-ignore
            showToast(error.response.data.errorMessage, "error")

        }



    }



    return (
        <React.Fragment>
            <CssBaseline />
            <Header />


            <main>


                <div className={classes.heroContent}>
                    <Container>

                    <div>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                //@ts-ignore
                                scroll={scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                            >
                                <DialogTitle id="scroll-dialog-title">RAW Details of Smitch Home Customer  </DialogTitle>
                                <DialogContent dividers={scroll === 'paper'}>
                                    <DialogContentText
                                        id="scroll-dialog-description"
                                        ref={descriptionElementRef}
                                        tabIndex={-1}
                                    >
                                        {JSON.stringify(users, null, 2)}
                                        {/* {JSON.stringify(users)} */}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                        <Grid>
                            <Paper className={classes.tabroot}>
                                <Tabs
                                    value={value}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Smitch Secure" />
                                    <Tab label="Smitch Home" />
                                    <Tab label="Smitch Wear" />
                                </Tabs>
                            </Paper>
                        </Grid>
                        <Grid container className="maincontainer">

                            <Grid item>
                                <form className={classes.root} noValidate autoComplete="off">
                                    <TextField
                                        id="outlined-basic"
                                        label="Email id"
                                        variant="outlined"
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            OR
                            <Grid item>
                                <form className={classes.root} noValidate autoComplete="off">
                                    <TextField
                                        id="outlined-basic"
                                        label="Mobile No"
                                        name="mobile"
                                        variant="outlined"
                                        type="tel"
                                        value={state.mobile}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            OR
                            <Grid>
                                <form className={classes.root} noValidate autoComplete="off">
                                    <TextField
                                        id="outlined-basic"
                                        label="User Id"
                                        name="user_id"
                                        variant="outlined"
                                        type="text"
                                        value={state.user_id}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            OR
                            <Grid>
                                <form className={classes.root} noValidate autoComplete="off">
                                    <TextField
                                        id="outlined-basic"
                                        label="Device Id"
                                        name="device_id"
                                        variant="outlined"
                                        type="text"
                                        value={state.device_id}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            <Grid />
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={userHandle}
                                >
                                    FIND
                                 </Button>
                            </Grid>

                        </Grid>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    <Grid className="maincontainer">
                        {users.length === 0 && deviceData.length === 0 && (
                            <h2>Your seach result will display here!</h2>
                        )}
                    </Grid>
                    {loading ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /> </div> :
                        <Grid container spacing={4} className="maincontainer"
                        >
                            {users.map((val, i) => (
                                <Grid item key={i} xs={12} sm={6} md={6} direction="column"
                                    justify="flex-start"
                                    alignItems="center">
                                    <Card className={classes.card} >

                                        <h4 style={{ textAlign: "center" }}>Send reset password link <IconButton aria-label="send" color="primary" onClick={() => resetLinkHandle(val)}>
                                            <SendIcon />
                                        </IconButton></h4>
                                        <Button ></Button>
                                    </Card>
                                    <br></br>

                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <Typography
                                                className={classes.title}
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                Customer's Details
                    </Typography>
                                            <CardHeader

                                                action={
                                                    <IconButton aria-label="settings" onClick={handleClickOpen('paper')}>
                                                        <LaunchIcon />
                                                    </IconButton>
                                                }
                                                title={val.name}
                                            />
                                            <Typography>Email : {val.email}</Typography>
                                            {/* <Typography>City : {val.city}</Typography> */}
                                            <Typography>Mobile No : {val.mobile}</Typography>
                                            <Typography>Mobile Type : {val.mobile_type}</Typography>
                                            <Typography>Account Created With : {val.login_type}</Typography>
                                            <Typography>App Version : {val.app_version}</Typography>
                                            {/* <Typography>App Notification enabled : {val.app_notifications_enabled ? "Yes"
                                            : "No"}</Typography> */}
                                            {/* <Typography>Home Name : {val.device.home_name.join()}</Typography> */}
                                            <Typography>Registered On : {moment(val.createdAt).format('LLLL')}</Typography>
                                            <Typography>Last Login : {moment(val.last_login_timestamp).format('LLLL')}</Typography>
                                            <Typography
                                                className={classes.title}
                                                gutterBottom
                                            >
                                                Event's Details
                                                  <IconButton aria-label="settings" onClick={handleClickOpenEvent('paper')}>
                                                    <LaunchIcon />
                                                </IconButton>
                                            </Typography>

                                            <Dialog
                                                open={openevent}
                                                onClose={handleCloseEvent}
                                                //@ts-ignore
                                                scroll={scroll}
                                                aria-labelledby="scroll-dialog-title"
                                                aria-describedby="scroll-dialog-description"
                                            >
                                                <DialogTitle id="scroll-dialog-title">Event's Details</DialogTitle>
                                                <DialogContent dividers={scroll === 'paper'}>
                                                    <DialogContentText
                                                        id="scroll-dialog-description"
                                                        ref={descriptionElementReftwo}
                                                        tabIndex={-1}
                                                    >
                                                        <div>{val.events.length > 0 ? val.events.map((one: any, i: any) =>
                                                        (<ul>{one.events_data.map((two: any, i: any) =>
                                                        (<div key={i}>
                                                            <Typography variant="body1" gutterBottom color="textSecondary">Event name : {two.event_name}</Typography>
                                                            <Divider />
                                                            <Typography color="textSecondary" >Start Time : {two.start_time}</Typography>
                                                            <Typography color="textSecondary">End Time : {two.end_time}</Typography>
                                                            <Typography color="textSecondary">Active : {two.active ? "Yes" : "No"}</Typography>
                                                            <Typography color="textSecondary">Days of Week : {two.days_of_week.map((day: any, i: any) => <Typography color="textSecondary" key={i}>{day}</Typography>)}</Typography>
                                                            {
                                                                two.device_actions.map((three: any, i: any) => (
                                                                    <div>
                                                                        <Typography color="textSecondary">Device Id : {three.device_id}</Typography>
                                                                        <Typography color="textSecondary">Device type : {three.device_type}</Typography>
                                                                        {three.rgb_document ? <div>
                                                                            {Object.keys(three.rgb_document).map((item) => {
                                                                                if (typeof (three.rgb_document[item]) === "object") {
                                                                                    return Object.keys(three.rgb_document[item]).map((inner_item) => {
                                                                                        return (<div>
                                                                                            <Typography >{inner_item} :{three.rgb_document[item][inner_item]}</Typography>
                                                                                        </div>
                                                                                        )
                                                                                    })
                                                                                } else {
                                                                                    return (
                                                                                        <div>
                                                                                            <Typography >
                                                                                                {(() => {
                                                                                                    if (three.rgb_document[item] && item !== "power_status" && item !== "online_status") {
                                                                                                        return (
                                                                                                            <div>{item} { three.rgb_document[item]}</div>
                                                                                                        )
                                                                                                    }
                                                                                                    else {
                                                                                                        return (
                                                                                                            <div>{item} { three.rgb_document[item] ? "YES" : "NO"}</div>
                                                                                                        )
                                                                                                    }
                                                                                                })()}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            })
                                                                            }</div> : null}
                                                                    </div>)
                                                                )
                                                            }
                                                        </div>
                                                        ))}</ul>)) : <p>No Event Found</p>}
                                                        </div>
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleCloseEvent} color="primary">
                                                        Ok
                                                </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Container>
                <Container className={classes.cardGrid} maxWidth="md">
                    <Grid container spacing={2}>
                        {devices.map((val, i) => (
                            <Grid item container key={i} xs={12} sm={6} md={6}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography
                                            className={classes.title}
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            Device {i + 1}'s Details
                                    </Typography>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            Name : {val.device.name}
                                        </Typography>
                                        {/* <Typography>Home Name : {val.device.home_names.home_name}</Typography> */}
                                        <Typography>Access Type : {val.access_type}</Typography>
                                        <Typography>
                                            Device Type :{val.device.device_type}
                                        </Typography>
                                        <Typography>
                                            Access Type :{val.access_type}
                                        </Typography>
                                        <Typography>
                                            Subscription Type :{val.device.device_subscription_type}
                                        </Typography>
                                        <Typography> Device Id :{val.device.device_id}</Typography>
                                        <Typography>
                                            Device Full type : {val.device_full_type}
                                        </Typography>
                                        <Typography>
                                            Manufacturer Batch : {val.device.manufacturer_batch_id}
                                        </Typography>
                                        <Typography>
                                            Hardware Version : {val.device.hard_ware_version}
                                        </Typography>
                                        <Typography>
                                            Subscription Type : {val.device.device_subscription_type}
                                        </Typography>
                                        <Typography>
                                            {" "}
                                        Firmware Version : {val.device.firmware_version}
                                        </Typography>
                                        <Typography>
                                            {" "}
                                        Firmware Update :
                                        {val.device.firmware_update ? "Yes" : "No"}
                                        </Typography>
                                        <Typography>
                                            Activated : {val.device.activated ? "Yes" : "No"}
                                        </Typography>
                                        <Typography>
                                            Device Added:   {moment(val.device_add_time).format('LLLL')}
                                        </Typography>

                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container style={{ margin: "auto", justifyContent: "center", marginTop: "-8rem" }}>
                        {/* 
                                        // @ts-ignore */}
                        {deviceData.map((device, i) => (
                            <Grid item key={i} xs={12} sm={6} md={6} style={{ margin: "auto", justifyContent: "center", marginTop: "-5rem" }}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                            <CardHeader
                                    action={
                                        <IconButton aria-label="settings" onClick={handleClickOpenDevice('paper')}>
                                            <LaunchIcon />
                                        </IconButton>
                                    }
                                    //@ts-ignore
                                    title={device.name}
                                />
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                        Name : {device.name}</Typography>
                                        <Typography>
                                        <div>
                            <Dialog
                                open={opendevice}
                                onClose={handleCloseDevice}
                                //@ts-ignore
                                scroll={scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                            >
                                <DialogTitle id="scroll-dialog-title">RAW Details of Smitch Home Device  </DialogTitle>
                                <DialogContent dividers={scroll === 'paper'}>
                                    <DialogContentText
                                        id="scroll-dialog-description"
                                        ref={descriptionElementRef}
                                        tabIndex={-1}
                                    >
                                        {JSON.stringify(device, null, 2)}
                                        {/* {JSON.stringify(users)} */}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDevice} color="primary">
                                        Ok
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                                            {/* 
                                        // @ts-ignore */}
                                        Device Id : {device.device_id}</Typography>
                                        {/* 
                                        // @ts-ignore */}
                                        <Typography>Manufacturer : {device.manufacturer}</Typography>
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                            Version : {device.version?device.version:"NA"}
                                        </Typography>
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                            Device Type : {device.device_type}
                                        </Typography>
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                            Activated : {device.activated ? "YES" : "NO"}
                                        </Typography>
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                            Firmware update : {device.firmware_update ? "YES" : "NO"}
                                        </Typography>
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                            Firmware Version : {device.firmware_version}
                                        </Typography>
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                            Home Name : {device.home_name}
                                        </Typography>
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                            Admin UserId : {device.admin_user_id}
                                        </Typography>
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                            Reported Stolen : {device.reported_stolen ? "Yes" : "No"}
                                        </Typography>
                                        {/* 
                                                                // @ts-ignore */}
                                        {device.reported_stolen ? <Button variant="outlined" color="primary" onClick={() => handleUnblock(device.device_id)}>
                                            UNBLOCK DEVICE
                                        </Button> : null}
                                        <Typography>
                                            {/* 
                                        // @ts-ignore */}
                                            Device Added: {moment(device.createdAt).format('LLLL')}
                                        </Typography>
                                        {/* 
                                        // @ts-ignore */}
                                        {device.spa_document != null ?
                                            <div>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}>
                                                            SPA Document Details
                                            </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography variant="body2">
                                                            Device Sub Type:{/* 
                                                        // @ts-ignore */}
                                                            {device.spa_document.device_sub_type}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Online Status  :{/* 
                                                        // @ts-ignore */}
                                                            {device.spa_document.online_status ? "YES" : "NO"}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            SPA_timezone   :{/* 
                                                        // @ts-ignore */}
                                                            {device.spa_document.spa_timezone}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            current Sensor  :{/* 
                                                        // @ts-ignore */}
                                                            {device.spa_document.current_sensor ? "YES" : "NO"}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Real_time_clock   :{/* 
                                                        // @ts-ignore */}
                                                            {device.spa_document.real_time_clock}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div> : null}
                                        {/* 
                                                        // @ts-ignore */}
                                        {device.spe_document != null ?
                                            <div>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}>
                                                            SPE Document Details
                                            </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography variant="body2">
                                                            Device Sub Type:{/* 
                                                        // @ts-ignore */}
                                                            {device.spe_document.device_sub_type}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Online Status  :{/* 
                                                        // @ts-ignore */}
                                                            {device.spe_document.online_status ? "YES" : "NO"}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            SPA_timezone   :{/* 
                                                        // @ts-ignore */}
                                                            {device.spe_document.spa_timezone}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            current Sensor  :{/* 
                                                        // @ts-ignore */}
                                                            {device.spe_document.current_sensor ? "YES" : "NO"}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Real_time_clock   :{/* 
                                                        // @ts-ignore */}
                                                            {device.spe_document.real_time_clock}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div> : null}
                                        {/* 
                                                        // @ts-ignore */}
                                        {device.rgbcct_document != null ?
                                            <div>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}>
                                                            RGBCCT Document Details
                                            </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography variant="body2">
                                                            Device Sub Type:{/* 
                                                        // @ts-ignore */}
                                                            {device.rgbcct_document.device_sub_type}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Online Status  :{/* 
                                                        // @ts-ignore */}
                                                            {device.rgbcct_document.online_status ? "YES" : "NO"}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Power Status  :{/* 
                                                        // @ts-ignore */}
                                                            {device.rgbcct_document.power_status ? "YES" : "NO"}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Brightness   :{/* 
                                                        // @ts-ignore */}
                                                            {device.rgbcct_document.brightness}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Temperature   :{/* 
                                                        // @ts-ignore */}
                                                            {device.rgbcct_document.temperature}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div> : null}
                                        {/* 
                                                                                         // @ts-ignore */}
                                        {device.room_name != null ? <div>
                                            <Typography>
                                                Room Name:
                                                                                        {/* 
                                                                                         // @ts-ignore */}
                                                {device.room_name}
                                            </Typography>
                                            <Typography>
                                                Manufacturer Batch Id:
                                                                                        {/* 
                                                                                         // @ts-ignore */}
                                                {device.manufacturer_batch_id}
                                            </Typography>
                                            <Typography>
                                                Device Subscription Type:
                                                                                        {/* 
                                                                                         // @ts-ignore */}
                                                {device.device_subscription_type}
                                            </Typography>
                                            {/* <Typography>
                                                Mqtt Broker Id  :
                                                {device.mqtt.broker_id? device.mqtt.broker_id: "NA"}
                                            </Typography> */}
                                            <Typography>
                                                Wifi SSID  :
                                                                    {/* 
                                                                                         // @ts-ignore */}
                                                {device.wifi_ssid}
                                            </Typography>
                                        </div> : null}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            {/* Footer section */}
            <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    IoT Monks Private Limited
                 </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    component="p"
                >
                    support@mysmitch.com | sales@mysmitch.com{" "}
                </Typography>
                <Copyright />
            </footer>
            {/* End footer */}
        </React.Fragment>
    );
};

export default ShUser;
