import React, { useState } from "react";

import { Button, Typography, TextField, Select, FormControl, MenuItem, InputLabel, Grid, makeStyles } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CircularProgress from '@material-ui/core/CircularProgress';

import Header from '../Components/header';
import '../Style/InternalUsers.css'
import { httpClient } from "../Common/Service";




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        color: 'white',
        width: 185,
        margin: 10

    },
    margin: {
        margin: theme.spacing(1),

    }, main:
    {
        width: 250
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4, 0, 3),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    title: {
        fontSize: 14,
    },
    tab: {
        width: "100%",
    },
}));


const Stats = () => {
    const classes = useStyles();
    const [android, setAndroid] = useState("")
    const [ios, setIos] = useState("")
    const [total, setTotal] = useState("")
    const [device, setDevice] = useState("")
    const [data, setData] = useState("")
    const [value, setValue] = useState(1);
    const [val, setVal] = useState("")
    const [loading, setLoading] = useState(false)




    const [state, setState] = useState({
        devType: "",
        app: "",
    });





    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        console.log("tab", newValue)
        if (newValue === 0) {
            //@ts-ignore
            fetchCount("SS")
        }
        if (newValue === 1) {
            //@ts-ignore
            fetchCount("SH")
        }
        if (newValue === 2) {
            //@ts-ignore
            fetchCount("SW")
        }
    }




    //base url change
    const handleChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
        //@ts-ignore


    };

    React.useEffect(() => {
        getToken();


    }, []);

    const getToken = () => {
        return localStorage.getItem("access_token");
    };

    const fetchCount = async (project: string) => {
        setLoading(true)
        try {
            //@ts-ignore
            const result = await httpClient("getLiveStats", "POST", { "project": project })
            console.log("stats", result.data)
            setAndroid(result.data.androidUserCount)
            setTotal(result.data.userCount)
            setIos(result.data.iOSUserCount)
            setDevice(result.data.deviceCount)
            setLoading(false)
            setVal(project)

        }
        catch (error) {
            console.error(error);
        }
    }


    const findCount = async () => {
        try {
            const result = await httpClient("getUserCount", "POST", { "appVersion": state.app, "mobileType": state.devType, "project": val })
            setData(result.data)
        } catch (error) {
            console.log(error)
        }
    }





    React.useEffect(() => {
        document.title = "Smitch | Stats "
        fetchCount("SH")
    }, []);



    return (
        <React.Fragment>
            <Header />
            <br>
            </br>
            <br></br>
            <br></br>
            <br></br>
            <div>
                <Paper className={classes.tab}>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Smitch Secure" />
                        <Tab label="Smitch Home" />
                        <Tab label="Smitch Wear" />

                    </Tabs>
                </Paper>

            </div>
            <CssBaseline />

            {loading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: "5rem" }}><CircularProgress /> </div> :
                <main>
                    {/* Hero unit */}
                    <div className={classes.heroContent}>
                        <Container maxWidth="sm">
                            <div className={classes.heroButtons}>
                                <Grid container spacing={2} justify="center">
                                    <Card className={classes.main}>
                                        <CardContent>

                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Total  Users
                                                </Typography>
                                            {loading ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /> </div> : <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                                                {total}
                                            </Typography>
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </div>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Android
                                        </Typography>
                                            <Typography>
                                                {android}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>

                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                ios
                                        </Typography>
                                            <Typography>
                                                {ios}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Device
                                        </Typography>
                                            <Typography>
                                                {device}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                    <Container className={classes.cardGrid} maxWidth="sm">
                        <Grid container>
                            <Grid item>
                                <FormControl variant="outlined" className={classes.root} >
                                    <InputLabel id="select-outlined-label" style={{ color: "black" }} >
                                        Mobile Type
                                </InputLabel>
                                    <Select
                                        labelId="select-outlined-label"
                                        id="simple-select-outlined"
                                        name="devType"
                                        label="User Type"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="ios">IOS</MenuItem>
                                        <MenuItem value="android">ANDROID</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <br></br>
                            <Grid item>
                                <form className={classes.root} noValidate autoComplete="off">
                                    <TextField
                                        style={{ color: "black" }}
                                        id="outlined-basic"
                                        label="App Version"
                                        variant="outlined"
                                        name="app"
                                        autoFocus
                                        value={state.app}
                                        onChange={handleChange}
                                    />
                                </form>
                            </Grid>
                            <Grid>
                                <Button variant="contained" onClick={findCount} color="primary" className="find">
                                    FIND
                            </Button>
                            </Grid>
                        </Grid>
                        
                        <Card >
                            <CardContent>
                                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                                    {data}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Container>
                </main>
            }
        </React.Fragment >
    );
}

export default Stats