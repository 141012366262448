import React, { useState } from "react";
import { Button, Typography, Grid, makeStyles, Paper, Box, CssBaseline, TextField } from '@material-ui/core';
import { useHistory } from "react-router"
import { ToastContext } from "../Common/ToastProvider";
import Copyright from '../Components/copyright'
import { Redirect } from 'react-router-dom';
import { BASE_URL } from '../Components/constant';
import axios from "axios";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/1600x900/?architecture)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const SignIn = () => {
    const { showToast } = React.useContext(ToastContext);
    let history = useHistory()
    const classes = useStyles();
    const [user, setUser] = useState({
        email: "",
        password: "",
    });
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };


    let icon: any;
    if (passwordShown == true) {
        icon = <VisibilityIcon onClick={togglePasswordVisiblity} />;
    } else if (passwordShown == false) {
        icon = <VisibilityOffIcon onClick={togglePasswordVisiblity} />;
    }


    const handleChange = (event: any) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const loginUser = async (user: any) => {
        try {
            const result = await axios.post(BASE_URL + 'login', user, { headers: { "encryption": false } })
            if (result != null) {
                let { access_token, developer_token } = result.data;
                localStorage.setItem("access_token", access_token);
                localStorage.setItem("developer_token", developer_token);
            }
            if (result != null) {
                history.push("/sh-home")
                showToast("login successful ", "success");
            }
            else {
                showToast("Invalid login credential", "error");
            }
        } catch (error) {
            console.log(error);
            showToast("Invalid login credential", "error");
        }
    };



    const isAuthenticated = () => {
        const token = localStorage.getItem("access_token")
        return token && token.length > 5;

    }


    let auth = isAuthenticated()

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const result = await loginUser(user)

    }
    React.useEffect(() => {
        document.title = "Smitch | Sign In "
    }, []);


    return (
        <div>
            { auth ? <Redirect
                to={{
                    pathname: "/ss-home"
                }}
            /> : <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <img
                            src={require("../../src/SMITCH.png")}
                            alt="mail logo"
                            width="60"
                            height="60"
                        />
                        <br></br>
                        <Typography component="h1" variant="h5">
                            Welcome Back!
                            </Typography>
                        <br></br>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email "
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={user.email}
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={passwordShown ? "text" : "password"}
                                id="password"
                                autoComplete="current-password"
                                value={user.password}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: icon
                                }}
                            />
                            {/* <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                /> */}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleSubmit}
                            >
                                Sign In
                                </Button>
                            {/* <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2">
                                            Forgot password?
                                    </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="#" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid> */}
                            <Box mt={8}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>}
        </div>
    )
}

export default SignIn


