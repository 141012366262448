import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isNotAuthenticated } from '../Common/helper'


const AuthenticatedRoute: React.FC<any> = ({
    component: Component,
    ...rest
}) => (
    <Route
        {...rest}
        render={props =>
            isNotAuthenticated() ? (
                <Redirect
                    to={{
                        pathname: "/",
                        state: { from: props.location },
                        search: `?redirect_to=${props.location.pathname}${props.location.search}${props.location.hash}`
                    }}
                />
            ) : (
                    <Component {...props} />
                )
        }
    />
);

export default AuthenticatedRoute