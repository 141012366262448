import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from "axios";
import { SWAGGER_WEARABLES, SWAGGER_SECURE, SWAGGER_HOME, SWAGGER_SC_BASE, SWAGGER_SSO,SC_INTRIGATION ,SMITCH_STORE } from '../Components/constant';
import Header from '../Components/header';




const useStyles = makeStyles({
    root: {
        width: "100%",
    },
});



const SwaggerDocs = () => {

    const classes = useStyles();
    const [swaggerUrl, setSwaggerUrl] = React.useState("");
    const [secureData, SetSecureData] = React.useState({})
    const [homeData, SetHomeData] = React.useState({})
    const [wearData, SetWearData] = React.useState({})
    const [healthData, SetHealthData] = React.useState({})
    const [sso, setSso] = React.useState({})
    const [integration, setIntegration]= React.useState({})
    const [store, setStore]= React.useState({})
    const [haSc, setHaSc]= React.useState({})

    const [loading, setLoading] = React.useState(true);





    const devToken = () => {
        return localStorage.getItem("developer_token");
    };
    const accessToken = () => {
        return localStorage.getItem("access_token");
    };
    const [value, setValue] = React.useState(4);


    React.useEffect(() => {
        document.title = "Smitch | Swagger Docs "
        getData()

    }, []);

    const Headers = {
        'Content-Type': 'application/json',
        "encryption": false,
        developer_token: devToken(),
        access_token: accessToken()
    }

    const getData = async () => {
        try {
            const result_ss = await axios.get(SWAGGER_SECURE, { headers: Headers })
            SetSecureData(result_ss.data)
            const result_sh = await axios.get(SWAGGER_HOME, { headers: Headers })
            SetHomeData(result_sh.data)
            const result_sw = await axios.get(SWAGGER_WEARABLES, { headers: Headers })
            SetWearData(result_sw.data)
            const result_sso = await axios.get(SWAGGER_SSO, { headers: Headers })
            setSso(result_sso.data)
            const result_sc = await axios.get(SWAGGER_SC_BASE+"docs", { headers: Headers })
            SetHealthData(result_sc.data)
            setSwaggerUrl(result_sc.data)
            const resut_sc_ha = await axios.get(SWAGGER_SC_BASE+"openapi-docs", { headers: Headers })
            setHaSc(resut_sc_ha.data)
            const result_sc_int = await axios.get(SC_INTRIGATION, { headers: Headers })
            setIntegration(result_sc_int.data)
            // const result_store = await axios.get(SMITCH_STORE, { headers: Headers })
            // setStore(result_store.data)
            setLoading(false)
        } catch (error) {
            console.log(error);

        }
    };


    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        if (newValue === 0) {
            //@ts-ignore
            setSwaggerUrl(secureData)
        }
        if (newValue === 1) {
            //@ts-ignore
            setSwaggerUrl(homeData)
        }
        if (newValue === 2) {
            //@ts-ignore
            setSwaggerUrl(wearData)
        }
        if (newValue === 3) {
            //@ts-ignore
            setSwaggerUrl(sso)
        }
        if (newValue === 4) {
            //@ts-ignore
            setSwaggerUrl(healthData)
        }
        if (newValue === 5) {
            //@ts-ignore
            setSwaggerUrl(haSc)
        }
        if (newValue === 6) {
            //@ts-ignore
            setSwaggerUrl(integration)
        }
        if (newValue === 7) {
            //@ts-ignore
            setSwaggerUrl(store)
        }
    }



    return (
        <React.Fragment>
            <Header />
            <br>
            </br>
            <br></br>
            <br></br>
            <br></br>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Smitch Secure" />
                    <Tab label="Smitch Home" />
                    <Tab label="Smitch Wearables" />
                    <Tab label="Single Sign On" />
                    <Tab label="Smitch Care" />
                    <Tab label="SC - Health Assistant" />

                    <Tab label="Smitch Care Integration" />
                    <Tab label="Smitch Store" />

                </Tabs>
            </Paper>
            {loading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop:"10rem" }}><CircularProgress />
             </div> :

            <SwaggerUI spec={swaggerUrl} />}
        </React.Fragment>
    )
}

export default SwaggerDocs
