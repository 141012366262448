import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import Switch from '@material-ui/core/Switch';

import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from '../Components/header';
import Copyright from "../Components/copyright";
import { ToastContext } from "../Common/ToastProvider";
import { NEW_BASE_URL } from '../Components/constant';

import '../App.css'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
        marginTop: 400
    },
}));

const WishTextHandler = () => {
    const { showToast } = React.useContext(ToastContext);
    const classes = useStyles();
    const [characterCount, setCharacterCount] = React.useState(0);
    const [selectedDate, handleDateChange] = React.useState(new Date("2021-11-02T14:15:44.044Z"));
    const [endDate, handleDateEnd] = React.useState(new Date("2021-11-02T14:15:44.044Z"));

    const CHARACTER_LIMIT = 250;
    const TITLE_LIMIT = 100;


    const [wish, setWish] = React.useState({
        title: "",
        body: "",
        url: "",
        isActive:false
    });


    const handleChange = (event: any) => {

        console.log("true==>", event.target.checked)
        setWish({ ...wish, [event.target.name]: event.target.value });
    };


    const handleTogllgeChange = (event: any) => {
        console.log("checked", event.target.checked)
        setWish({ ...wish, [event.target.name]: event.target.checked});
    };


    const handleBodyChange = (event: any) => {
        setCharacterCount(event.target.value.length)
        setWish({ ...wish, [event.target.name]: event.target.value });
    };



    React.useEffect(() => {
        getWishText()
      }, []);
    

    const getWishText = async () => {
        try {
            const result = await axios.get(NEW_BASE_URL + `chatsupportconfig/list?order=ASC`, {
                headers: {
                    "encryption": false,
                    "access_token": getToken()
                }
            })
            if (result.data && result.data.data) {
                let newTitle=result.data.data[0].title
                let newUrl=result.data.data[0].image_url
                let newDescription=result.data.data[0].description
                let newActive=result.data.data[0].is_active
                handleDateChange(result.data.data[0].start_date)
                handleDateEnd(result.data.data[0].end_date)
                setWish({...wish , title:newTitle, url:newUrl, body:newDescription ,isActive:newActive })
            }
        } catch (error) {
            console.error("error", error);
            //@ts-ignore
            showToast(error.response.data.errorMessage, "error")

        }
    };



    const updateWish = async (e: any,) => {
        try {
            e.preventDefault()
            if(!wish.title || !wish.body){
                showToast("Pleaes write a wish title and wish text body first!", "warn");

            }

            else{
            const result = await axios.put(NEW_BASE_URL + 'chatsupportconfig/modify', {
                "title": wish.title,
                "description": wish.body,
                "image_url": wish.url,
                "chat_support_config_id": 1,
                "is_active": wish.isActive,
                "start_date":selectedDate,
                "end_date":endDate

            }, {
                headers: {
                    "encryption": false,
                    "access_token": getToken()
                }
            })
            if (result != null) {
                showToast(result.data.message, "success");
            }
        }
        } catch (error) {
            console.log(error);
            //@ts-ignore
            showToast(error.response.data.errorMessage, "error")
        }
    };







    const getToken = () => {
        //@ts-ignore
        return localStorage.getItem("access_token")
    }




    return (
        <React.Fragment>
            <Header />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <Container component="main" maxWidth="md" style={{ paddingTop: "10rem" }} >
                <div style={{ textAlign: "center", }}>
                            <h3 >
                                Create  A  Wish or Greetings Here
                </h3>
                            <p>Create wish text to display on chat support page for users. </p>
                        </div>
                    <div >
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="title"
                                label="Write wish title "
                                name="title"
                                autoFocus
                                value={wish.title}
                                onChange={handleChange}
                                inputProps={{ maxLength: TITLE_LIMIT }}


                            />
                            {/* <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                name="url"
                                fullWidth
                                id="url"
                                label="Enter Banner Image Url "
                                value={wish.url}
                                onChange={handleChange}
                            /> */}
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="body"
                                label="Write wish text body"
                                type="body"
                                id="body"
                                multiline
                                rows={3}
                                value={wish.body}
                                onChange={handleBodyChange}
                                inputProps={{ maxLength: CHARACTER_LIMIT }}
                            />
                            <p>Number of characters left: {CHARACTER_LIMIT - characterCount}</p>
                            <br/>

                            <div style={{ width: "100%" }}>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    label="Start time"
                                    style={{ width: "240px" }}
                                    value={selectedDate}
                                    //@ts-ignore
                                    onChange={handleDateChange}
                                />
                                &nbsp; &nbsp;  &nbsp; &nbsp;

                                <DateTimePicker
                                    inputVariant="outlined"
                                    label="End time"
                                    style={{ width: "240px" }}

                                    value={endDate}
                                    //@ts-ignore
                                    onChange={handleDateEnd}
                                />
                                 &nbsp; &nbsp;  &nbsp; &nbsp;
                                <FormControlLabel
                                name="isActive"
                                    //@ts-ignore
                                    control={<Switch checked={wish.isActive}                                 onChange={handleTogllgeChange}
                                    />}
                                    label="Active"
                                />

                            </div>
                            <br/>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={updateWish}
                            >
                                Submit wish
                            </Button>
                        </form>
                    </div>
                    <footer className={classes.footer}>
                        <Typography variant="h6" align="center" gutterBottom>
                            IoT Monks Private Limited
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            align="center"
                            color="textSecondary"
                            component="p"
                        >
                            support@mysmitch.com | sales@mysmitch.com{" "}
                        </Typography>
                        <Copyright />
                    </footer>
                </Container>
            </MuiPickersUtilsProvider>

        </React.Fragment>

    );
}


export default WishTextHandler